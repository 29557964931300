const isSmall = () =>
  typeof window !== 'undefined' ? window.matchMedia('(max-width: 450px)').matches : false
const isMedium = () =>
  typeof window !== 'undefined' ? window.matchMedia('(max-width: 768px)').matches : false
const isLarge = () =>
  typeof window !== 'undefined' ? window.matchMedia('(max-width: 1170px)').matches : false
const isHuge = () =>
  typeof window !== 'undefined' ? window.matchMedia('(max-width: 1440px)').matches : false

const mediaQuery = { isSmall, isMedium, isLarge, isHuge }

module.exports = mediaQuery
