import React from 'react'

const ArrowIcon = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.63807 11.1428L12.3047 6.47617C12.5651 6.21582 12.5651 5.79371 12.3047 5.53336L7.63807 0.866695L6.69526 1.8095L10.2239 5.33815L0.5 5.33815V6.67148L10.2238 6.67148L6.69526 10.2L7.63807 11.1428Z" fill="#FFFFFF"/>
  </svg>
)

const UnitiesIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 20.9111H21M3.67857 3.66667H18.3214H3.67857ZM3.67857 3.66667H18.4107V21H3.67857V3.66667ZM18.3214 20.9111H3.67857H18.3214ZM18.3214 3.66667H3.67857L4.92857 1H17.0714L18.3214 3.66667Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.98214 6.60001C6.80357 6.60001 6.625 6.51112 6.625 6.33334M12.9643 18.9556C12.9643 17.8889 12.0714 17 11 17C9.92857 17 9.03571 17.8889 9.03571 18.9556V20.9111H13.0536V18.9556H12.9643ZM6.98214 5.97778C7.16071 5.97778 7.33929 6.15556 7.33929 6.33334L6.98214 5.97778ZM6.625 6.33334C6.625 6.15556 6.80357 5.97778 6.98214 5.97778L6.625 6.33334Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.98214 10.5999C6.80357 10.5999 6.625 10.4221 6.625 10.2444M7.33929 6.33325C7.33929 6.51103 7.16071 6.68881 6.98214 6.68881L7.33929 6.33325ZM6.98214 9.9777C7.16071 9.9777 7.33929 10.1555 7.33929 10.3333L6.98214 9.9777ZM6.625 10.2444C6.625 10.0666 6.80357 9.88881 6.98214 9.88881L6.625 10.2444Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.625 14.3333C6.625 14.1555 6.80357 13.9777 6.98214 13.9777M7.33929 10.2444C7.33929 10.4222 7.16071 10.5999 6.98214 10.5999L7.33929 10.2444ZM6.98214 13.9777C7.16071 13.9777 7.33929 14.1555 7.33929 14.3333L6.98214 13.9777Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6429 6.33334C10.6429 6.15556 10.8214 5.97778 11 5.97778M6.98214 14.6C6.80357 14.6 6.625 14.4222 6.625 14.2444L6.98214 14.6ZM7.33929 14.3333C7.33929 14.5111 7.16071 14.6889 6.98214 14.6889L7.33929 14.3333ZM11 5.97778C11.1786 5.97778 11.3571 6.15556 11.3571 6.33334L11 5.97778Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11 10.5999C10.8214 10.5999 10.6428 10.4222 10.6428 10.2444M11 6.59994C10.8214 6.59994 10.6428 6.42216 10.6428 6.24438L11 6.59994ZM11.3571 6.33327C11.3571 6.51105 11.1785 6.68883 11 6.68883L11.3571 6.33327ZM11 9.97772C11.1785 9.97772 11.3571 10.1555 11.3571 10.3333L11 9.97772ZM10.6428 10.2444C10.6428 10.0666 10.8214 9.88883 11 9.88883L10.6428 10.2444Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6428 14.3333C10.6428 14.1555 10.8214 13.9777 11 13.9777M11.3571 10.2444C11.3571 10.4222 11.1785 10.5999 11 10.5999L11.3571 10.2444ZM11 13.9777C11.1785 13.9777 11.3571 14.1555 11.3571 14.3333L11 13.9777Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.6607 6.33334C14.6607 6.15556 14.8393 5.97778 15.0178 5.97778M11 14.6C10.8214 14.6 10.6428 14.4222 10.6428 14.2444L11 14.6ZM11.3571 14.3333C11.3571 14.5111 11.1785 14.6889 11 14.6889L11.3571 14.3333ZM15.0178 5.97778C15.1964 5.97778 15.375 6.15556 15.375 6.33334L15.0178 5.97778Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.018 10.5999C14.8395 10.5999 14.6609 10.4222 14.6609 10.2444M15.018 6.59994C14.8395 6.59994 14.6609 6.42216 14.6609 6.24438L15.018 6.59994ZM15.3752 6.33327C15.3752 6.51105 15.1966 6.68883 15.018 6.68883L15.3752 6.33327ZM15.018 9.97772C15.1966 9.97772 15.3752 10.1555 15.3752 10.3333L15.018 9.97772ZM14.6609 10.2444C14.6609 10.0666 14.8395 9.88883 15.018 9.88883L14.6609 10.2444Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.6609 14.3333C14.6609 14.1555 14.8395 13.9777 15.018 13.9777M15.3752 10.2444C15.3752 10.4222 15.1966 10.5999 15.018 10.5999L15.3752 10.2444ZM15.018 13.9777C15.1966 13.9777 15.3752 14.1555 15.3752 14.3333L15.018 13.9777Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.3752 14.3335C15.3752 14.5113 15.1966 14.6891 15.018 14.6891M15.018 14.6002C14.8395 14.6002 14.6609 14.4224 14.6609 14.2446L15.018 14.6002Z" stroke="#FEFEFE" strokeWidth="1.53333" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const GoogleDocs = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M17.7084 17.7083C17.7084 18.1504 17.5328 18.5743 17.2203 18.8868C16.9077 19.1994 16.4838 19.375 16.0417 19.375H3.95841C3.51639 19.375 3.09246 19.1994 2.7799 18.8868C2.46734 18.5743 2.29175 18.1504 2.29175 17.7083V2.29167C2.29175 1.84964 2.46734 1.42572 2.7799 1.11316C3.09246 0.800595 3.51639 0.625 3.95841 0.625H12.2917L17.7084 6.04167V17.7083Z" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.7084 6.04167H13.9584C13.5164 6.04167 13.0925 5.86607 12.7799 5.55351C12.4673 5.24095 12.2917 4.81703 12.2917 4.375V0.625L17.7084 6.04167Z" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5834 8.54175H5.41675" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5834 11.1833H5.41675" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5834 13.8167H5.41675" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.80008 16.4583H5.41675" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>
)

const CommonFileTextClock = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M9.375 14.375C9.375 15.7011 9.90178 16.9729 10.8395 17.9105C11.7771 18.8482 13.0489 19.375 14.375 19.375C15.7011 19.375 16.9729 18.8482 17.9105 17.9105C18.8482 16.9729 19.375 15.7011 19.375 14.375C19.375 13.0489 18.8482 11.7771 17.9105 10.8395C16.9729 9.90178 15.7011 9.375 14.375 9.375C13.0489 9.375 11.7771 9.90178 10.8395 10.8395C9.90178 11.7771 9.375 13.0489 9.375 14.375Z" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.585 14.375H14.375V12.165" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.125 5.625H11.875" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.125 9.375H8.125" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.125 13.125H6.25" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.25 16.875H1.875C1.54348 16.875 1.22554 16.7433 0.991117 16.5089C0.756696 16.2745 0.625 15.9565 0.625 15.625V1.875C0.625 1.54348 0.756696 1.22554 0.991117 0.991117C1.22554 0.756696 1.54348 0.625 1.875 0.625H10.7325C11.0638 0.625071 11.3815 0.756651 11.6158 0.990833L14.0092 3.38417C14.2433 3.6185 14.3749 3.93621 14.375 4.2675V6.25" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>
)

export {
  ArrowIcon,
  UnitiesIcon,
  GoogleDocs,
  CommonFileTextClock,
}
