import React, { memo } from 'react'

import { DividerStyle, DividerLine } from './styled'

const Divider = () => (
  <DividerStyle>
    <DividerLine />
  </DividerStyle>
)

export default memo(Divider)
