import React, { useState, createContext } from 'react'

const MainContext = createContext()

export const MainProvider = props => {
  const [isMobile, setIsMobile] = useState(false)

  // mobile
  const setIsMobileState = open => {
    setIsMobile(open)
  }

  const getIsMobileState = () => {
    return isMobile
  }

  return (
    <MainContext.Provider
      value={{
        setIsMobileState,
        getIsMobileState,
      }}
      {...props}
    />
  )
}

export const useMainContext = () => {
  const context = React.useContext(MainContext)
  if (context === undefined) {
    throw new Error('useHeader must be used within a MainProvider')
  }
  return context
}
