import styled from 'styled-components'
import media from 'styled-media-query'

import { customMedia } from '../../utils/breakpoints';

export const QuickAccessStyled = styled.div`
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${customMedia.lessThan('xs')`
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    padding-top: 0;
    padding-bottom: 20px;
  `}
`
export const Background = styled.div`
  background-color: ${props => props.theme.fastAcess.header};
`

export const Container = styled.div`
  display: flex;
  justify-content: center;

  ${customMedia.greaterThan('lg')`
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 140px;
  `}

  ${customMedia.lessThan('lg')`
    padding: 0 144px;
  `}

  ${customMedia.lessThan('sm')`
    padding: 0;
  `}

  ${customMedia.lessThan('xs')`
    margin: 0;
  `}
  
`

export const QuickAccessCardStyled = styled.a`
  outline: none;
  text-decoration: none;

  ${customMedia.lessThan('md')`
    padding: 0 14px;
  `}

  ${customMedia.lessThan('sm')`
    padding: 0 14px;
  `}
  ${customMedia.lessThan('xs')`
    padding: 0 14px;
  `}
`
export const QuickAccessCardMobileStyled = styled.a`
  width: 100%;
  outline: none;
  display: flex;
  text-decoration: none;
  box-sizing: border-box;

  ${customMedia.lessThan('xs')`
    padding-top: 33px;
    padding-bottom: 30px;
    padding-left: 16px;
    padding-right 16px;
  `}
`

export const QuickAccessCardTitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${customMedia.lessThan('xs')`
    place-items: flex-end;
  `}

`
export const QuickAccessCardTitleCallToActionContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 8px;  
`

export const QuickAccessCardIconContainer = styled.div`
  border-radius: 100%;
  background: rgba(254, 254, 254, 0.12);
  width: 40px;
  height: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${customMedia.lessThan('xs')`
    margin-right: 22px;
    width: 48px;
    height: 48px;
  `}
  
`

export const QuickAccessCardTitle = styled.h2`
  height: 24px;
  font-family: ${props => props.theme.font};
  font-size: 20px;
  line-height: 120%;
  font-weight: bold;
  color: ${props => props.theme.fastAcess.title};

  ${customMedia.lessThan('md')`
    font-size: 16px;
    line-height: 150%;
  `}

  ${customMedia.lessThan('sm')`
    font-size: 16px;
    line-height: 150%;
  `}

  ${customMedia.lessThan('xs')`
    font-size: 16px;
    line-height: 150%;
  `}
`

export const QuickAccessCardDescription = styled.p`
  font-family: ${props => props.theme.font};
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.baseColors.white};
  margin-bottom: 20px;

  ${customMedia.greaterThan('lg')`
    width: 265px;
  `}

  ${customMedia.lessThan('md')`
    line-height: 170%;
  `}

  ${customMedia.lessThan('sm')`
    display: none;
  `}

  ${customMedia.lessThan('sm')`
    display: none;
  `}

`

export const QuickAccessCardCallToActionText = styled.p`
  font-weight: bold;
`

export const QuickAccessCardCallToAction = styled.div`
  font-family: ${props => props.theme.font};
  font-size: 14px;
  line-height: 115%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.fastAcess.link};
  p {
    padding-right: 6px;
  }

  ${customMedia.lessThan('sm')`
    padding-top: 12px;
    padding-left: 10px;
  `}

  ${customMedia.lessThan('xs')`
    padding-top: 0;
    padding-left: 0;
    justify-content: center;
  `}

`

export const DividerStyle = styled.div`
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 70px;

  ${customMedia.lessThan('md')`
    display: none;
  `}

  ${customMedia.lessThan('md')`
    display: none;
  `}

  ${customMedia.lessThan('xs')`
    width: 100%;
    height: 0;
  `}
`

export const DividerLine = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(254, 254, 254, 0.24);
  ${media.lessThan('large')`
    height: 1px;
    width: 100%;
    background: rgba(22, 22, 22, 0.12);
  `}
`
