import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { isMobile } from '@cms/utils'

import { useMainContext } from './Hooks/mainStates'

import Theme from './Themes'
import GlobalStyle from './GlobalStyle'

import Dynatrace from './Dynatrace'

import '../assets/styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as S from './styled'

import { QuickAccess } from './QuickAcess'
import SEO from './seo'

const Header = React.lazy(() =>
  import('./Header')
)
const HomeBanner = React.lazy(() =>
  import('./HomeBanner')
)
const Showcase = React.lazy(() =>
  import('./Showcase')
)
const Footer = React.lazy(() =>
  import('./Footer')
)
const Button = React.lazy(() =>
  import('./FloatingButton')
)
const CardExamesContainer = React.lazy(() =>
  import('./CardExames')
)
const VirtualAssistant = React.lazy(() =>
  import('./VirtualAssistant')
)

const Layout = () => {
  const { site: { siteMetadata, }, titleSite, floatingButton, virtualAssistant, seo, examSettings } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          brand
          siteUrl
        }
      }
      titleSite: wordpressSiteMetadata {
        name
        description
      }
      floatingButton: wordpressExecutiveThemeSettings {
        floating_button_url
      }
      virtualAssistant: wordpressExecutiveThemeSettings {
        virtual_assistant_url
      }
      seo: wordpressWpGatsbyPage(slug: { eq: "home" }) {
        acf {
          title
          description
          canonical
        }
      }
      examSettings: wordpressWpGatsbyPage(slug: {eq: "exames"}) {
        acf {
          enable_scheduling
          scheduling_button_label
        }
      }
    }
  `)

  const isSSR = typeof window === "undefined"
  const screen = typeof window !== `undefined` ? window.screen : false

  const isMobileCheck = useMainContext()
  const isMobileState = isMobileCheck.getIsMobileState()

  const [isVirtualAssistentOpen, setIsVirtualAssistentOpen] = useState(true)


  useEffect(() => {
    if (isMobile(screen)) {
      isMobileCheck.setIsMobileState(true)
    }
    window.addEventListener('message', function(event) {
      try {
        if (!virtualAssistant) return;
        const data = event.data;
        const dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push(data);
        if (data.event === "chatbot_interaction_close") {
          setIsVirtualAssistentOpen(false);
        }
      } catch(e) {}
    })
  }, [screen])

  const awsBucket = process.env.GATSBY_AWS_BUCKET;
  const nameBrand = siteMetadata.brand;
  const finalUrl = `${awsBucket}/dynatrace/dynatrace-${nameBrand}.js`;

  return (
    <>
      <SEO
        title={seo.acf.title}
        description={seo.acf.description}
        meta={[{name: "robots", content: "index, follow"}]}
        lang={'pt-br'}
        canonical={seo.acf.canonical}
      />

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Theme>
            <>
              <Dynatrace dynatraceUrl={finalUrl} siteBrand={nameBrand}/>
              <GlobalStyle />
              <S.MainHome>
                <Header isMobile={isMobileState} brand={siteMetadata.brand} />
                <HomeBanner isMobile={isMobileState} />
                <QuickAccess
                  titleSite={titleSite}
                  siteBrand={siteMetadata}
                  isMobile={isMobileState}
                />
                <Showcase brand={siteMetadata.brand} />
                <CardExamesContainer isMobile={isMobileState} />
              </S.MainHome>
              <Footer brand={siteMetadata.brand} />
              { examSettings.acf.enable_scheduling &&
                <Button floatingButtonLabel={examSettings.acf.scheduling_button_label} floatingButtonUrl={floatingButton.floating_button_url} />
              }
              {
                virtualAssistant.virtual_assistant_url &&
                <VirtualAssistant
                  isMobile={isMobileState}
                  brand={siteMetadata.brand}
                  opened={isVirtualAssistentOpen}
                  virtualAssistantUrl={virtualAssistant.virtual_assistant_url}
                />
              }
            </>
          </Theme>
        </React.Suspense>
      )}
    </>
  )
}

export default Layout
