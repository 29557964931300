import React from 'react'
import { Helmet } from 'react-helmet'

const Dynatrace = ({dynatraceUrl, siteBrand}) => {
  const awsBucket = process.env.GATSBY_AWS_BUCKET;
  const finalUrl = `${awsBucket}/dynatrace/dynatrace-${siteBrand}.js`;

  return (
    <Helmet>
      <script src={dynatraceUrl == finalUrl ? dynatraceUrl : "dynatraceUrlInvalid"} type="text/javascript" />
    </Helmet>
  )
}

export default Dynatrace
