import { createGlobalStyle, keyframes } from 'styled-components'


const FadeIn = keyframes`{
    from {
      opacity: 0;
      visibility: visible;
      display: block;
    }
    to {
      opacity: 1;
    }
  }`
  
  const FadeOut = keyframes`{
    from {
     opacity: 1;
    }
    to {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }`


const GlobalStyle = createGlobalStyle`


  html {
    font-size: 10px;
    font-family: ${props => props.theme.font};
    body {
      margin: 0;
      padding: 0;
    }

    a, h1, h2, h3, h4, h5, h6, p, strong, .accordion-title {
      font-family: ${props => props.theme.font};
    }

    strong {
      font-weight: bold;
    }    
  }

  /* svg colors */
  .primary-fill-color {
    fill: ${props => props.theme.colors.primary} !important;
  }

  /* block main scroll */
  .scroll {
    overflow-y: scroll;
    height: 100vh;
  }

  .block-scroll {
    overflow-y: hidden;
    max-height: 100vh;
  }

  /* mobile animation */
  .open-animation {
    animation: .5s ${FadeIn} forwards;
  }

  .close-animation {
    animation: .5s ${FadeOut} forwards;
  }

  .d-none {
    display: none;
  }

  #ot-sdk-btn-floating.ot-floating-button {
    display: none;
  }
`

export default GlobalStyle