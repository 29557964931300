import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import QuickAccessCard from './QuickAccessCard'
import Divider from './Divider'

import { CommonFileTextClock, GoogleDocs, UnitiesIcon} from './Icons'
import { Container, QuickAccessStyled, Background } from './styled'


const QuickAccess = ({ isMobile }) => {

  const { dataQuickAccess } = useStaticQuery(graphql`
      query {
        dataQuickAccess: allWordpressAcfQuickAccess {
          edges {
            node {
              acf {
                quick_title
                quick_description
                quick_link
                quick_order
                quick_cta
                quick_icon
             }
           }
         }
       }
      }
    `)

    const dataQuickAccessCard = isMobile ? 
      dataQuickAccess.edges.sort((a,b) => a.node.acf.quick_order - b.node.acf.quick_order) :
      dataQuickAccess.edges.sort((b,a) => a.node.acf.quick_order - b.node.acf.quick_order)

      function cardConstructor(item) {
        const {quick_title, quick_description, quick_link, quick_icon, quick_cta} = item.node.acf;
        let new_quick_icon;

        switch(quick_icon) {
          case "resultado":
            new_quick_icon = GoogleDocs;
            break;
          case "agendamento":
            new_quick_icon = CommonFileTextClock;
            break;
          case "unities":
            new_quick_icon = UnitiesIcon;
            break;
          default:
            new_quick_icon = GoogleDocs;
            break;
        }

        const quick_id = quick_title.split(' ').map(item => item[0].toUpperCase()+ item.substr(1)).join('');

        const objetoSaida = {
            id: `quickAccess${quick_id}`,
            title: quick_title,
            description: quick_description,
            link: quick_link,
            icon: new_quick_icon,
            callToActionText: quick_cta,
        }

        return objetoSaida
      }

      const quickAccessLinks = dataQuickAccessCard.map(item => {
        return cardConstructor(item)
      })

  const CardElement = ({id, title, description, link, callToActionText,  mobileDevice, icon, index, arrSize}) =>{
    return (
      <>
        {index !== arrSize && index !== 0 && <Divider data-testid={`${id}-divider-${index}`} />}
        <QuickAccessCard
          data-testid={id}
          isMobile={mobileDevice}
          title={title}
          description={description}
          link={link}
          icon={icon}
          callToActionText={callToActionText}
        />
      </>
    )
  }

  return (
    <Background data-testid="quick-access-background">
      <Container data-testid="quick-access-container">
        <QuickAccessStyled data-testid="quickAccessCardContainer">
          {quickAccessLinks.map((quickAccessLink, index) => {
            const { id, title, description, link, callToActionText, icon } = quickAccessLink
            return (
              <CardElement
                key={id}
                mobileDevice={isMobile}
                data-testid={id}
                title={title}
                description={description}
                link={link}
                callToActionText={callToActionText}
                icon={icon}
                index={index}
                arrSize={quickAccessLink.length}
              />
            )
          })}
          </QuickAccessStyled>
      </Container>
    </Background>
  )
}

export default QuickAccess
