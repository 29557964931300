import { useStaticQuery, graphql } from 'gatsby'
import baseTheme from './baseTheme'

const colorTheme = () => {
  const { allWordpressAcfBrandTheme, nav } = useStaticQuery(
    graphql`
      query {
        nav: wordpressWpGatsbyPage(slug: {eq: "home"}) {
          acf {
            nav_background_color
          }
        }
        allWordpressAcfBrandTheme {
          edges {
            node {
              acf {
                acesso_rapido {
                  fundo
                  link
                  titulo
                }
                banner {
                  stepper
                  stepper_loading
                }
                cor_primaria
                cor_secundaria
                cor_terciaria
                exames_mais_buscados {
                  tag_convenios
                  tag_particular
                  texto_busca
                  texto_tag
                  titulo_da_secao
                  titulo_do_card
                  botao_agendar
                }
                fonte
                footer_desktop {
                  fundo_copyright
                  icones_social
                  linha_copyright
                  texto_copyright
                  titulo
                }
                header {
                  botao_flutuante_bg
                  botao_flutuante_text
                  icone_dasa
                  texto_dos_menus
                  titulo_submenu_hamburguer
                }
                footer_mobile {
                  titulo
                  icones_social
                }
                servicos_para_voce {
                  fundo
                  saiba_mais
                  texto_card
                  titulo_card
                  titulo_do_texto
                  titulo_section
               }
             }
           }
         }
       }
      }
    `
  )

  const dataWp = allWordpressAcfBrandTheme.edges[0]?.node.acf

  //Fonte
  const fonte = dataWp.fonte === "dasa" ? 'Dasa Sans' : 'Encode Sans'

  //Cores primarias, secundarias e terciarias
  const primaryColor = dataWp.cor_primaria
  const secondaryColor = dataWp.cor_secundaria
  const tertiaryColor = dataWp.cor_terciaria

  //Header
  const menuText = dataWp.header.texto_dos_menus
  const iconDasa = dataWp.header.icone_dasa
  const submenuTitleHamb = dataWp.header.titulo_submenu_hamburguer
  const bgFloating = dataWp.header.botao_flutuante_bg
  const colorFloating = dataWp.header.botao_flutuante_text
  const backgroundLogoNav = nav.acf.nav_background_color

  //Banner
  const stepper = dataWp.banner.stepper
  const stepperLoading = dataWp.banner.stepper_loading

  //Acesso rápido
  const quickAccessBg = dataWp.acesso_rapido.fundo
  const quickAccessTitle = dataWp.acesso_rapido.titulo
  const quickAccessLink = dataWp.acesso_rapido.link

  //Servicos para voce
  const serviceBg = dataWp.servicos_para_voce.fundo
  const serviceKnowMore = dataWp.servicos_para_voce.saiba_mais
  const serviceCardText = dataWp.servicos_para_voce.texto_card
  const serviceCardTitle = dataWp.servicos_para_voce.titulo_card
  const serviceTitle = dataWp.servicos_para_voce.titulo_do_texto
  const serviceSectionTitle = dataWp.servicos_para_voce.titulo_section

  //Exames mais buscados
  const showcaseSectionTitles = dataWp.exames_mais_buscados.titulo_da_secao
  const showcaseCardTitles = dataWp.exames_mais_buscados.titulo_do_card
  const showcaseSearchText = dataWp.exames_mais_buscados.texto_busca
  const showcaseTagPriv = dataWp.exames_mais_buscados.tag_particular
  const showcaseTagPub = dataWp.exames_mais_buscados.tag_convenios
  const showcaseTagColor = dataWp.exames_mais_buscados.texto_tag

  //Footer mobile
  const footerMobileTitle = dataWp.footer_mobile.titulo
  const footerMobileIcon = dataWp.footer_mobile.icones_social

  //Footer Desktop
  const footerIcon = dataWp.footer_desktop.icones_social
  const footerTitle = dataWp.footer_desktop.titulo
  const footerCrBg = dataWp.footer_desktop.fundo_copyright
  const footerCrText = dataWp.footer_desktop.texto_copyright
  const footerCrLine = dataWp.footer_desktop.linha_copyright

  const preDefined = {
    primary: primaryColor,
    secondary: secondaryColor,
    tercenary: tertiaryColor,
  }
  const finalTheme = {
    ...baseTheme,
    font: fonte,
    header: {
      background: baseTheme.baseColors.pureWhite,
      textBurguerButton: menuText,
      text: menuText,
      iconDasa: iconDasa,
      floatingButtonText: colorFloating,
      floatingButtonBg: bgFloating,
      navLogo: backgroundLogoNav
    },
    menu: {
      border: baseTheme.baseColors.lightGray,
      text: menuText,
      submenuTitle: submenuTitleHamb,
      list: baseTheme.baseColors.pureWhite,
    },
    bannerHome: {
      dots: stepper,
      loadingDots:stepperLoading,
    },
    fastAcess: {
      header: quickAccessBg,
      title: quickAccessTitle,
      link: quickAccessLink,
    },
    services4u: {
      titleSection: serviceSectionTitle,
      title: serviceCardTitle,
      text: serviceCardText,
      textMore: serviceKnowMore,
      background: serviceBg,
      header: serviceTitle,
    },
    showcase: {
      background: baseTheme.baseColors.pureWhite,
      textSectionTitle: showcaseSectionTitles,
      textCardTitle: showcaseCardTitles,
      text: showcaseCardTitles,
      textResultSearch: showcaseSearchText,
      coveredTag: showcaseTagPub,
      noCoveredTag: showcaseTagPriv,
      textTag: showcaseTagColor,
    },
    footer: {
      mobile: {
        social: {
          icon: footerMobileIcon,
        },
        menu: {
          background: baseTheme.baseColors.pureWhite,
          text: baseTheme.baseColors.darkGray,
          title: footerMobileTitle,
        },
        copyright: {
          background: baseTheme.baseColors.pureWhite,
          text: baseTheme.baseColors.darkGray,
          line: baseTheme.baseColors.lightGray,
        },
      },
      desktop: {
        social: {
          text: baseTheme.baseColors.pureWhite,
          icon: footerIcon,
        },
        menu: {
          background: baseTheme.baseColors.pureWhite,
          text: baseTheme.baseColors.darkGray,
          title: footerTitle,
        },
        copyright: {
          background: footerCrBg,
          text: footerCrText,
          line: footerCrLine,
        },
      },
    },
  }

  return finalTheme

}

export default colorTheme
