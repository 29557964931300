const slug = require('slug')
const capitalizer = require('capitalize-pt-br')

const keepUpperCase = [
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'vi',
  'vii',
  'viii',
  'ix',
  'x',
  'xi',
  'xii',
  'xiii',
  'vx',
  'xv',
  'xvi',
  'xvii',
  'xviii',
  'xix',
  'xx',
  'ac,',
  'al,',
  'ap,',
  'am,',
  'ba,',
  'ce,',
  'df,',
  'es,',
  'go,',
  'ma,',
  'mt,',
  'ms,',
  'mg,',
  'pa,',
  'pb,',
  'pr,',
  'pe,',
  'pi,',
  'rj,',
  'rn,',
  'rs,',
  'ro,',
  'rr,',
  'sc,',
  'sp,',
  'se,',
  'to,',
]

const capitalize = text => {
  return text ? capitalizer(text, [], keepUpperCase).replace(' i', ' I') : ''
}
const slugfy = text => slug(text, { lower: true, remove: /[().]/g })

const serializeUrls = text => text.replace(/"/g, '')

const removeLastChar = text => text.split('').slice(0, -1).join('')

const formatTextToNumbers = text => text.replace(/\D/gim, '')

const htmlentities = {
  /**
   * Converts a string to its html characters completely.
   *
   * @param {String} str String with unescaped HTML characters
   * */
  encode(str) {
    const buf = []

    for (let i = str.length - 1; i >= 0; i -= 1) {
      buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''))
    }

    return buf.join('')
  },

  /**
   * Converts an html characterSet into its original character.
   *
   * @param {String} str htmlSet entities
   * */
  decode(str) {
    return str.replace(/&#(\d+);/g, (_, dec) => {
      return String.fromCharCode(dec)
    })
  },
}

const normalizeText = text => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

module.exports = { slugfy, capitalize, serializeUrls, htmlentities, removeLastChar, formatTextToNumbers, normalizeText }
