const axios = require('axios')
// const dotenv = require('dotenv')

// dotenv.config({
//   path: '../../.env',
// })

let backOfficeToken = null

const getBackOfficeToken = async () => {
  if (backOfficeToken) {
    return backOfficeToken
  }
  const params = {
    email: process.env.BACKOFFICE_API_EMAIL,
    password: process.env.BACKOFFICE_API_PASSWORD,
  }
  const res = await axios.post(`${process.env.BACKOFFICE_API_URL}/api/login`, params)
  setBackOfficeToken(res)

  return getBackOfficeToken()
}

const setBackOfficeToken = apiResponse => {
  try {
    backOfficeToken = apiResponse.data.data.api_token
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

module.exports = getBackOfficeToken
