const formatPhoneMask = phoneValue => {
  let phone = phoneValue
  phone = phone.replace(/\D/g, '')
  phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2')
  phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')
  if (phone.length === 15) {
    phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')
  }

  return phone
}

module.exports = formatPhoneMask
