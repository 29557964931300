const replaceSpecialChars = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase() // Remove acentos
    .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
    .replace(/--+/g, '-') // Substitui multiplos hífens por um único hífen
    .replace(/(^-+|-+$)/g, '') // Remove hífens extras do final ou do inicio da string
}

module.exports = replaceSpecialChars
