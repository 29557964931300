import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import colorTheme from './colorTheme'

const Theme = ({ children }) => {

  return <ThemeProvider theme={colorTheme}>{children}</ThemeProvider>
}

Theme.propTypes = {
  children: PropTypes.element,
}

export default Theme
