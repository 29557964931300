const isHome = location => {
  const url = location.pathname
  return url.length <= 1
}

const isMobile = screen => {
  return screen ? screen.width < 768 : false
}

const isUrl = text => {
  if (typeof text !== 'string') return false

  return (
    text.match(
      /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    ) != null
  )
}

// used in href target attribute
const isExternalLink = text => text.match(/^http|https?:\/\//g) ? '_blank' : null

module.exports = { isHome, isMobile, isUrl, isExternalLink }
