import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title, canonical }) {
  const { site, schema, favicon } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        schema: wordpressExecutiveThemeSettings {
          brand_name
          brand_url
          facebook_url
          instagram_url
          linkedin_url
          phone_1
          phone_2
          youtube_url
        }
        favicon: allWordpressAcfBrandTheme {
          edges {
            node {
              acf {
                favicon {
                  localFile {
                    url
                  }
                }
             }
           }
         }
       }
      }
    `
  )

  const dataSchema = {
    '@context': 'https://schema.org',
    '@type': 'DiagnosticLab',
    name: schema.brand_name,
    url: schema.brand_url,
    logo: '',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: schema.phone_1,
        contactType: 'customer service',
        areaServed: 'BR',
        availableLanguage: 'Portuguese',
      },
      {
        '@type': 'ContactPoint',
        telephone: schema.phone_2,
        contactType: 'customer service',
        areaServed: 'BR',
        availableLanguage: 'Portuguese',
      },
    ],
    sameAs: [schema.facebook_url, schema.linkedin_url, schema.youtube_url, schema.instagram_url],
  }

  // const faviconWpLink = favicon.edges[0]?.node.acf.favicon.localFile.childImageSharp.fluid.src
  const faviconWpLink = favicon.edges[0]?.node.acf.favicon.localFile.url
  const awsAssetsBucket = process.env.GATSBY_AWS_BUCKET;
  const defaultFavicon = `${awsAssetsBucket}/logos/favicon/default-favicon.png`
  const faviconLink = faviconWpLink ? faviconWpLink : defaultFavicon



  const metaDescription = description || site.siteMetadata.description

  const linkCanonical = canonicalUrl => {
    if (!canonicalUrl) {
      return {}
    }

    return {
      rel: 'canonical',
      key: canonicalUrl,
      href: canonicalUrl,
    }
  }

  return (
    <Helmet
      link={[linkCanonical(canonical)]}
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          'http-equiv': `X-Frame-Options`,
          content: `DENY`,
        },
        {
          name: `cms:sites`,
          content: `cms:sites`,
        },
      ].concat(meta)}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(dataSchema),
        },
      ]}
    >
      <link rel="shortcut icon" href={faviconLink} />
      <script>
        {`
          if (window.top !== window.self) {
            window.top.location = window.self.location;
          }
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  canonical: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
}

export default SEO
