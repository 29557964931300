const baseTheme = {
  fonts: ['sans-serif', 'Arimo'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
  colors: {
    primary: '#fff',
    white: '#fff',
  },
  baseColors: {
    gray: '#DFE1E7',
    lightGray: '#e7e7e7',
    darkGray: '#4B4B4B',
    white: '#FEFEFE',
    pureWhite: '#FFFFFF',
    blueNav: '#0037FF',
  }
}

export default baseTheme
