import React, { memo } from 'react'

import {
  QuickAccessCardStyled,
  QuickAccessCardTitleIconContainer,
  QuickAccessCardIconContainer,
  QuickAccessCardDescription,
  QuickAccessCardMobileStyled,
  QuickAccessCardTitle,
  QuickAccessCardCallToAction,
  QuickAccessCardTitleCallToActionContainer,
  QuickAccessCardCallToActionText,
} from './styled'

import { ArrowIcon } from './Icons'

const CallToAction = ({callToActionText}) => {
  return (
    <QuickAccessCardCallToAction>
      <QuickAccessCardCallToActionText>{callToActionText}</QuickAccessCardCallToActionText>
      <ArrowIcon />
    </QuickAccessCardCallToAction>
  )
}

const handleTag = (eventLabel) => {
  if (window && window.dataLayer)
      window.dataLayer.push({
      'event': 'institucional_acessoRapido',
      'action': 'clicou_btn',
      'clique': eventLabel
  })
}

const QuickAccessCard = ({ title, description, link, isMobile, callToActionText, icon }) => {
  const CardIcon = icon

  if(isMobile) {
    return (
      <QuickAccessCardMobileStyled
        data-testid="quickAcessCardMobileContainer"
        data-item-text={title}
        data-item-position="Atalhos Hero"
        data-current-service="Home"
        href={link} onClick={() => handleTag(title)}
      >
        <QuickAccessCardIconContainer>
          <CardIcon />
        </QuickAccessCardIconContainer>
        <QuickAccessCardTitleCallToActionContainer>
          <QuickAccessCardTitle data-testid="quickAccessCardMobileCallToActionTitle">{title}</QuickAccessCardTitle>
          <CallToAction data-testid="quickAccessCardMobileCallToAction" callToActionText={callToActionText} />
        </QuickAccessCardTitleCallToActionContainer>
      </QuickAccessCardMobileStyled>
    )
  }

  return (
    <QuickAccessCardStyled
      data-testid="quickAcessCardDesktopContainer"
      data-item-text={title}
      data-item-position="Atalhos Hero"
      data-current-service="Home"
      href={link}
      onClick={() => handleTag(title)}
    >
      <QuickAccessCardTitleIconContainer>
        <QuickAccessCardIconContainer>
        <CardIcon />
        </QuickAccessCardIconContainer>
        <QuickAccessCardTitle data-testid="quickAccessCardDesktopCallToActionTitle">{title}</QuickAccessCardTitle>
      </QuickAccessCardTitleIconContainer>
      <QuickAccessCardDescription data-testid="quickAccessCardDescription">{description}</QuickAccessCardDescription>
      <CallToAction data-testid="quickAccessCardDesktopCallToAction" callToActionText={callToActionText} />
    </QuickAccessCardStyled>
  )
}

export default memo(QuickAccessCard)
